/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {Languages} from './Languages'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {useDispatch} from 'react-redux'
import {toAbsoluteUrl} from '../../../helpers'
import {bool} from "yup";

const HeaderUserMenu: FC = () => {
    const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as boolean
    let user: UserModel | undefined = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;
    const history = useHistory();

    if(!isAuthorized)
        user = {first_name: "Guest", last_name: "User", email: "--", id: -1, username: "guestuser", password: "---", type: "standard"}

    if(!user)
        history.push("/auth/login")


    const dispatch = useDispatch()
    const logout = () => {
        dispatch(auth.actions.logout())
        history.push("/dashboard")
    }

    const login = () => {
        history.push("/auth/login")
    }

    return (
        <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
            data-kt-menu='true'
        >
            <div className='menu-item px-3'>
                <div className='menu-content d-flex align-items-center px-3'>
                    <div className='symbol symbol-50px me-5'>
                        <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')}/>
                    </div>

                    <div className='d-flex flex-column'>
                        <div className='fw-bolder d-flex align-items-center fs-5'>
                            {user.first_name} {user.last_name}
                        </div>
                        <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                            {isAuthorized && user.email}
                        </a>
                    </div>
                </div>
            </div>

            <div className='separator my-2'></div>

            {isAuthorized && <div className='menu-item px-5'>
                <Link to={'/userprofile/overview'} className='menu-link px-5'>
                    Il mio profilo
                </Link>
            </div>}


            {isAuthorized && <div className='menu-item px-5'>
                <a href='/statistics' className='menu-link px-5'>
                    Statistiche
                </a>
            </div>}

            <div className='separator my-2'></div>

            <div className='menu-item px-5'>
                {isAuthorized && <a onClick={logout} className='menu-link px-5'>
                    Sign Out
                </a>}
                {!isAuthorized && <a onClick={login} className='menu-link px-5'>
                    Log In
                </a>}
            </div>
        </div>
    )
}

export {HeaderUserMenu}
