import React, {useEffect} from 'react'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {MenuComponent} from '../assets/ts/components'
import {CartDrawer} from "../../app/pages/partial/CartDrawer";
// import {DrawerMessenger} from "../partials";

// @ts-ignore
window.cart = []

const MasterLayout: React.FC = ({children}) => {
    const location = useLocation()
    useEffect(() => {
        setTimeout(() => {
            MenuComponent.reinitialization()
        }, 500)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            MenuComponent.reinitialization()
        }, 500)
    }, [location.key])

    return (
        <PageDataProvider>
            <div className='page d-flex flex-row flex-column-fluid'>
                <AsideDefault/>
                <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
                    <HeaderWrapper/>

                    <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                        <Toolbar/>
                        <div className='post d-flex flex-column-fluid' id='kt_post'>
                            <Content>{children}</Content>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>

            {/* begin:: Drawers */}
            {/*<ActivityDrawer/>*/}
            {/*<RightToolbar/>*/}
            {/*<DrawerMessenger/>*/}
            <CartDrawer/>
            {/* end:: Drawers */}

            {/* begin:: Modals */}
            {/*<Main/>*/}
            {/*<InviteUsers/>*/}
            {/*<UpgradePlan/>*/}
            {/* end:: Modals */}
            {/*<ScrollTop/>*/}
        </PageDataProvider>
    )
}

export {MasterLayout}
