import {useState} from "react";

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

function getGeocodedAddress(obj: any, key: string|number, lng: number, lat: number, fu: any){
    fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng}%2C%20${lat}.json?types=address&access_token=pk.eyJ1IjoidGV0b2ZvbnRhIiwiYSI6ImNrcmtkYTdqNDBiOTQyb2w3MW5kb3JoNDUifQ.yLWOuvoLPmhCHIxvL46DhQ`)
        .then(res => res.json())
        .then(data => {
            if(data.features && data.features.length > 0 && data.features[0].place_name)
                obj[key].geocoded_address = data.features[0].place_name
            fu && fu()
        })
    obj[key].geocoded_address = "..."
    return "..."
}

export {useForceUpdate, getGeocodedAddress}