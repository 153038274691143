import React, {lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {DashboardWrapper} from "../pages/dashboard/DashboardWrapper";
import {AuthPage} from "../modules/auth";

export function PublicRoutes() {

    return (
        <Switch>
            <Route path='/dashboard' component={DashboardWrapper}/>
            <Route path='/' exact component={DashboardWrapper}/>
            <Redirect to='/auth'/>
        </Switch>
    )
}
