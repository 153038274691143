/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {KTSVG} from "../../../_metronic/helpers";
import {getGeocodedAddress, useForceUpdate} from "../../utils";
import {UserModel} from "../../modules/auth/models/UserModel";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {useHistory} from "react-router-dom";



const CartDrawer: FC = () => {
    const forceUpdate = useForceUpdate()
    const history = useHistory()
    const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as boolean

    let user: UserModel | undefined = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;
    // @ts-ignore
    const cart = window.cart
    // @ts-ignore
    window.update_cart_drawer = forceUpdate

    return <div
        id='kt_drawer_cart'
        className='bg-white'
        data-kt-drawer='true'
        data-kt-drawer-name='cart'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'300px', 'md': '500px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_drawer_cart_toggle'
        data-kt-drawer-close='#kt_drawer_cart_close'
    >
        <div className='card w-100 rounded-0'>
            <div className='card-header pe-5'>
                <div className='card-title'>
                    <div className='d-flex justify-content-center flex-column me-3'>
                        <a href='#' className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'>
                            Carrello
                        </a>
                    </div>
                </div>

                <div className='card-toolbar'>

                    <div className='btn btn-sm btn-icon btn-active-light-primary' id='kt_drawer_cart_close'>
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2'/>
                    </div>
                </div>
            </div>

            <div className='card-body'>
                {cart && cart.map((e: any, i: number) =>
                    <div className={"row"}>
                        <div className={"col-1"}
                             style={{marginRight: ".8rem", marginTop: "auto", marginBottom: "auto"}}>
                            <KTSVG
                                path='/media/icons/duotune/electronics/elc008.svg'
                                className={"svg-icon-3x svg-icon-warning"}
                            />
                        </div>
                        <div className={"col"}>
                            <h3>{e.type ? (e.type === "buy" ? "Acquisto stazione" : ( e.type === "allow" ? "Autorizzazione di piazzamento" : "Proposta")) : "Da decidere"}</h3>
                            <p style={{
                                wordWrap: "break-word",
                                textOverflow: "ellipsis"
                            }}>{e.geocoded_address || getGeocodedAddress(cart, i, e.position[0], e.position[1], forceUpdate)}</p>
                        </div>
                        <div className={"col-1 g-1"} style={{marginTop: "auto", marginBottom: "auto"}}>
                            <div
                                className={'btn btn-icon btn-active-light-primary btn-custom position-relative w-30px h-30px w-md-40px h-md-40px'}
                                onClick={() => {
                                    cart.splice(i, 1)
                                    // @ts-ignore
                                    window.update_dashboard && window.update_dashboard()
                                    forceUpdate()
                                }}
                            >
                                <KTSVG
                                    path='/media/icons/duotune/general/gen027.svg'
                                    className={"svg-icon-2x svg-icon-danger"}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className='card-footer'>
                <div className="row">
                    <div className="col-8">
                        <button className="btn btn-primary" disabled={!cart.map((e: any) => !!e.type).reduce((a:boolean, b: boolean) => a && b, true)} onClick={() => {
                            if(!isAuthorized) history.push('/auth')
                            else
                                fetch("/api/purchase", {method: "POST", headers: {"Content-Type": "application/json"}, body: JSON.stringify({cart, token: user?.token})})
                                .then(r => r.json())
                                .then(data => {
                                    if(data.redirect !== null)
                                        // @ts-ignore
                                        window.open(data.redirect);
                                    else {
                                        alert("Proposta inviata.")
                                        // @ts-ignore
                                        eval("location.reload()")
                                    }
                                })
                        }}>
                            { user?.type === "consulent" ? "Proponi" : "Procedi all'acquisto"}
                        </button>
                    </div>
                    <div className="col-4">
                        <h1 style={{margin: 'auto'}}>{cart.map((e: any) => e.type === "buy" ? 150 : 0).reduce((a: number, b: number) => a+b, 0).toFixed(2)} EUR</h1>
                    </div>
                </div>
            </div>

        </div>
    </div>
}

export {CartDrawer}
