import axios from 'axios'
import {UserModel} from '../models/UserModel'

const API_URL = "/api"

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string) {
    return fetch(LOGIN_URL, {method: "POST", headers: {"Content-Type": "application/json"}, body: JSON.stringify({email, password})})
        .then(e => {
            if(e.status != 200) throw Error()
            return e
        })
        .then(e => e.json())
        .then(res => ({data: res}))
}

// Server should return AuthModel
export function register(email: string, first_name: string, last_name: string, password: string, password_confirmation: string) {
    return new Promise((res, rej) => {
        if(password !== password_confirmation)
            rej()
        res(true)
    }).then(_ => fetch(REGISTER_URL, {method: "POST", headers: {"Content-Type": "application/json"}, body: JSON.stringify({email, first_name, last_name, password})}))
        .then(e => {
            if(e.status != 200) throw Error()
            return e
        })
        .then(e => e.json())
        .then(res => ({data: res}))
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
    return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
        email
    })
}

export function getUserByToken(token: string) {
    return fetch(GET_USER_BY_ACCESSTOKEN_URL, {method: "POST", headers: {"Content-Type": "application/json"}, body: JSON.stringify({token})})
        .then(e => {
            if(e.status != 200) throw Error()
            return e
        })
        .then(e => e.json())
        .then(res => ({data: res}))
}
