/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch, useHistory} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";

export function AuthPage() {
    useEffect(() => {
        document.body.classList.remove('bg-white')
        // document.body.classList.add('bg-white')
        // return () => {
        //     document.body.classList.remove('bg-white')
        // }
    }, [])

    const hist = useHistory()
    const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as boolean

    return (
        <div
            className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
            style={{
                backgroundColor: "rgb(24,28,50)"
            }}
        >
            {/* begin::Content */}
            <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                {/* begin::Logo */}
                <a href='#' className='mb-12'>
                    <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-1-dark.png')} className='h-60px'/>
                </a>
                {/* end::Logo */}
                {/* begin::Wrapper */}
                <div className='w-lg-500px rounded shadow-sm p-10 p-lg-15 mx-auto' style={{
                    backgroundColor: "rgb(34,38,60)"
                }}>
                    <Switch>
                        <Route path='/auth/login' component={Login}/>
                        <Route path='/auth/registration' component={Registration}/>
                        <Route path='/auth/forgot-password' component={ForgotPassword}/>
                        <Redirect from='/auth' exact={true} to='/auth/login'/>
                        <Redirect to='/auth/login'/>
                    </Switch>
                </div>
                {/* end::Wrapper */}
            </div>
            {/* end::Content */}
            {/* begin::Footer */}
            <div className='d-flex flex-center flex-column-auto p-10'>
                <div className='d-flex align-items-center fw-bold fs-6'>
                    <a href='#' className='text-muted text-hover-primary px-2'>
                        About
                    </a>

                    <a href='#' className='text-muted text-hover-primary px-2'>
                        Contattaci
                    </a>

                    <a href='#' className='text-muted text-hover-primary px-2'>
                        Privacy policy
                    </a>
                </div>
            </div>
            {/* end::Footer */}
        </div>
    )
}
