export default function setupAxios(axios: any, store: any) {
    axios.defaults.headers.Accept = 'application/json'
    axios.interceptors.request.use(
        (config: any) => {
            const {
                auth: {accessToken},
            } = store.getState()

            if (accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`
            }

            return config
        },
        (err: any) => Promise.reject(err)
    )
}
