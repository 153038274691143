/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'

import ReactMapboxGl, {Layer, Feature, Popup, Marker} from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css'

import {KTSVG, toAbsoluteUrl} from "../../../_metronic/helpers";
import {Modal} from "react-bootstrap-v5";
import clsx from "clsx";
import {HeaderNotificationsMenu} from "../../../_metronic/partials";
import {CompassControl, RulerControl, StylesControl, ZoomControl} from "mapbox-gl-controls";
import {getGeocodedAddress, useForceUpdate} from "../../utils";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {LngLatBounds} from "mapbox-gl";
import {UserModel} from "../../modules/auth/models/UserModel";

const metersPerPixel = function (latitude: number, zoomLevel: number) {
    let earthCircumference = 40075017;
    let latitudeRadians = latitude * (Math.PI / 180);
    return earthCircumference * Math.cos(latitudeRadians) / Math.pow(2, zoomLevel + 8);
};

const pixelValue = function (latitude: number, meters: number, zoomLevel: number) {
    return meters / metersPerPixel(latitude, zoomLevel);
};

const accessToken = 'pk.eyJ1IjoiY2lhbWJlbGxlZGlnaXRhbGkiLCJhIjoiY2wxamZ4eGNyMHNwcDNqbWpuMGF5OHU2aCJ9.ffAH4PH9PY1FeEjO4pvifg'
const Map = ReactMapboxGl({
    accessToken: accessToken,
    antialias: true
});

// @ts-ignore
const cart = window.cart

let popups: { [pos: string]: { [k: string]: any } } = {}
let newHotspotMode = false;

// @ts-ignore
const DashboardPage: FC = () => {
    const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as boolean
    let user: UserModel | undefined = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;

    const [zoom, setZoom] = useState(11);
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);

    const [fov, setFov] = useState(new LngLatBounds([0, 0, 0, 0]));

    const [mapComponent, setMap] = useState(undefined as any);
    const map: any = useRef(null);

    const [showGlobalHS, setShowGlobalHS] = useState(true);
    const [showMyHS, setShowMyHS] = useState(isAuthorized);
    const [showHelpPlacement, setShowHelpPlacement] = useState(false);

    const [my_hotspots, set_my_hotspots] = useState({} as any);
    const [my_hotspots_loaded, set_loaded] = useState(false);
    const [estimated, set_estimated] = useState("--");

    const forceUpdate = useForceUpdate();
    // @ts-ignore
    window.update_dashboard = forceUpdate
    let [geolocation, set_geolocation] = useState(false);
    let [init, set_init] = useState(false);

    useEffect(() => {
        if(map.current && map.current.state && map.current.state.map && !mapComponent) {
            setMap(map.current.state.map)

            map.current.state.map.addControl(new RulerControl({
                units: 'kilometers',
                labelFormat: n => `${n.toFixed(2)} km`,
            }), 'bottom-right');

            map.current.state.map.addControl(new StylesControl({
                styles: [
                    {
                        label: 'MAP',
                        styleName: 'Mapbox Streets',
                        styleUrl: 'mapbox://styles/mapbox/dark-v10',
                    }, {
                        label: 'SAT',
                        styleName: 'Satellite',
                        styleUrl: 'mapbox://styles/mapbox/satellite-v9',
                    },
                ],
                onChange: (style) => console.log(style),
            }), 'top-left');

            map.current.state.map.addControl(new ZoomControl(), 'top-right');

            map.current.state.map.addControl(new CompassControl(), 'top-right');

            //for pc
            map.current.state.map.doubleClickZoom.disable();

            forceUpdate()

        } else if ("geolocation" in navigator && !geolocation && mapComponent) {
            console.log("geolocalizing...")
            set_geolocation(true)
            navigator.geolocation.getCurrentPosition(function (position) {
                console.log("updating location...", position.coords)
                setLat(position.coords.latitude)
                setLng(position.coords.longitude)
                mapComponent.flyTo({center: [position.coords.longitude, position.coords.latitude]})
            });
        }
    });

    if(!my_hotspots_loaded && isAuthorized) {
        set_loaded(true)
        const mh = {} as any
        // @ts-ignore
        //window.cart = []
        popups = {}
        fetch("/api/get_installations", {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({token: user.token})
        })
            .then(res => res.json())
            .then(data => {
                data.res.forEach((h: any, i: number) => {
                    if(h.node_address){
                        fetch(`https://api.helium.io/v1/hotspots/${h.node_address}`)
                            .then(res => res.json())
                            .then(res => {
                                mh[i].name = res.data.name
                                forceUpdate()
                            })
                    }
                    mh[i] = {
                        address: h.node_address,
                        name: h.node_address ? "fetching..." : "Installazione in corso...",
                        position: [h.lng, h.lat],
                        is_visible: false,
                        is_area_visible: false
                    }
                })
                set_my_hotspots(mh)
            })
    }

    const populate_map = (cursor ?: string, bbox?: LngLatBounds) => {
        const popup_radius = bbox ? Math.floor(Math.max(bbox.getNorthEast().distanceTo(bbox.getCenter()), bbox.getSouthWest().distanceTo(bbox.getCenter()))) : 4000
        let coords = bbox?.getCenter()
        if(coords)
            fetch(`https://api.helium.io/v1/hotspots/location/distance?lat=${bbox?.getCenter().lat.toFixed(6)}&lon=${bbox?.getCenter().lng.toFixed(6)}&distance=${popup_radius}${cursor ? "&cursor=" + cursor : ""}`)
                .then(res => res.json())
                .then(data => {
                    if(!data.data) return;
                    data.data.forEach((e: { address: string, lat: number, lng: number, name: string }) => {
                        if (!popups[e.address] && e.lat && e.lng) {
                            popups[e.address] = {
                                address: e.address,
                                position: [e.lng, e.lat],
                                is_visible: false,
                                is_area_visible: false,
                                name: e.name
                            }
                        }
                    })
                    if (data.cursor)
                        populate_map(cursor)
                    else
                        forceUpdate()
                })
    }

    if (Object.keys(popups).length === 0)
        populate_map()

    if(!init) {
        let interval = setInterval(() => {
            console.log("try")
            if(map.current && map.current.state && map.current.state.map){
                set_init(true)
                // forceUpdate()
                console.log("init")
                clearInterval(interval)
            }
        }, 200)
    }

    const addToCart = (map: any, evt: any) => {
        console.log(user)
        // if(user?.type === "admin") return;
        cart.forEach((e: any) => {if(e.type) e.is_visible = false})
        forceUpdate();
        if(!newHotspotMode) return;
        let pos = evt.lngLat
        console.log("add cart item", pos)
        cart.push({address: "Da Acquistare", position: [pos.lng, pos.lat], is_visible: true, is_area_visible: false, type: user?.type === "consulent" ? "proposed" : undefined})
        // @ts-ignore
        window.update_cart_drawer && window.update_cart_drawer()

        const e = cart[cart.length - 1]
        fetch(`https://api.helium.io/v1/hotspots/location/distance?lat=${e.position[1]}&lon=${e.position[0]}&distance=${600}`)
            .then(res => res.json())
            .then(res => set_estimated(`${res.data.length * 0.02}`))

        forceUpdate()
    }

    return <>

        <div className={"d-flex flex-row"} style={{height: '97%'}}>
            {showMyHS && <div className='col-xl-4'>
                <div className="card card-custom card-stretch shadow mb-5" style={{height: '100%', marginRight: 15, marginBottom: "0px !important"}}>
                    <div className="card-header">
                        <div>
                        <h3 className="card-title">I miei hotspot</h3>
                        <p className={"card-subtitle"}>{Object.keys(my_hotspots).length} hotspot totali</p>
                        </div>
                    </div>
                    <div className="card-body">
                        {Object.keys(my_hotspots).map(e =>
                            <div className={"row"}>
                                <div className={"col-1"}
                                     style={{marginRight: ".8rem", marginTop: "auto", marginBottom: "auto"}}>
                                    <KTSVG
                                        path='/media/icons/duotune/electronics/elc008.svg'
                                        className={"svg-icon-3x svg-icon-primary"}
                                    />
                                </div>
                                <div className={"col"}>
                                    <h3>{my_hotspots[e].name.replace(/-/g, " ").split(" ").map((e: string) => e.charAt(0).toUpperCase() + e.slice(1)).join(" ")}</h3>
                                    <p style={{wordWrap: "break-word", textOverflow: "ellipsis"}}>{my_hotspots[e].geocoded_address || getGeocodedAddress(my_hotspots, e, my_hotspots[e].position[0], my_hotspots[e].position[1], forceUpdate)}</p>
                                </div>
                                <div className={"col-1 g-1"} style={{marginTop: "auto", marginBottom: "auto"}}>
                                    <div
                                        className={'btn btn-icon btn-active-light-primary btn-custom position-relative w-30px h-30px w-md-40px h-md-40px'}
                                        onClick={() => {
                                            mapComponent && mapComponent.flyTo({center: my_hotspots[e].position})
                                        }}
                                    >
                                        <KTSVG
                                            path='/media/icons/duotune/maps/map007.svg'
                                            className={"svg-icon-1 svg-icon-white"}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>}
            <div className='col'>
                <div className="row" style={{height: 'calc(100% - 4rem)'}}>
                    <Map
                        ref={map}
                        style="mapbox://styles/mapbox/dark-v10"
                        onMoveEnd={(map) => {
                            console.log("onmoveend")
                            let newpos = map.getCenter()
                            setFov(map.getBounds())
                            populate_map(undefined, map.getBounds());
                            setZoom(map.getZoom())
                        }}
                        onClick={addToCart}
                        containerStyle={{
                            height: '100%',
                            width: '100%'
                        }}>
                        <>
                            {showGlobalHS && Object.keys(popups).filter((e: string) => fov.contains(popups[e].position)).map((e: string) =>
                                <>
                                    <Marker
                                        key={e}
                                        anchor={"center"}
                                        coordinates={popups[e].position}
                                        onClick={() => {
                                            popups[e].is_visible = !popups[e].is_visible
                                            Object.keys(popups).filter(q => q != e).forEach(e => popups[e].is_visible = false)
                                            forceUpdate();
                                        }}
                                        onMouseEnter={() => {
                                            popups[e].is_area_visible = true
                                            forceUpdate()
                                        }}
                                    >
                                        <KTSVG
                                            path='/media/icons/duotune/electronics/elc008.svg'
                                            className={"svg-icon-2x svg-icon-white"}
                                        />
                                    </Marker>
                                    {popups[e].is_visible &&
                                        <Popup key={"popup" + e} coordinates={popups[e].position}>
                                            <h2>{popups[e].name.replace(/-/g, " ").split(" ").map((e: string) => e.charAt(0).toUpperCase() + e.slice(1)).join(" ")}</h2>
                                            <h5>{e}</h5>
                                        </Popup>}
                                </>
                            )}

                            {showMyHS && Object.keys(my_hotspots).filter((e: string) => fov.contains(my_hotspots[e].position)).map((e: string) =>
                                <>
                                    <Marker
                                        key={"my" + e}
                                        anchor={"center"}
                                        coordinates={my_hotspots[e].position}
                                        onClick={() => {
                                            my_hotspots[e].is_visible = !my_hotspots[e].is_visible
                                            forceUpdate();
                                        }}
                                        onMouseEnter={() => {
                                            my_hotspots[e].is_area_visible = true
                                            forceUpdate()
                                        }}
                                    >
                                        <KTSVG
                                            path='/media/icons/duotune/electronics/elc008.svg'
                                            className={"svg-icon-2x svg-icon-primary"}
                                        />
                                    </Marker>
                                    {my_hotspots[e].is_visible &&
                                        <Popup key={"popup" + e} coordinates={my_hotspots[e].position}>
                                            <h2>{my_hotspots[e].name.replace(/-/g, " ").split(" ").map((e: string) => e.charAt(0).toUpperCase() + e.slice(1)).join(" ")}</h2>
                                            <h5>{my_hotspots[e].address}</h5>
                                        </Popup>}
                                </>
                            )}

                            {/*cart*/}
                            {cart.map((e: any, i: number) =>
                                <>
                                    <Marker
                                        key={"cart" + i}
                                        anchor={"center"}
                                        coordinates={e.position}
                                        onClick={() => {
                                            cart[i].is_visible = !cart[i].is_visible



                                            fetch(`https://api.helium.io/v1/hotspots/location/distance?lat=${e.position[1]}&lon=${e.position[0]}&distance=${600}`)
                                                .then(res => res.json())
                                                .then(res => set_estimated(`${res.data.length * 0.02}`))
                                            set_estimated("loading...")

                                            forceUpdate();
                                        }}
                                        onMouseEnter={() => {
                                            cart[i].is_area_visible = true
                                            forceUpdate()
                                        }}
                                    >
                                        <KTSVG
                                            path='/media/icons/duotune/electronics/elc008.svg'
                                            className={"svg-icon-2x svg-icon-danger"}
                                        />
                                    </Marker>
                                    {e.is_visible &&
                                        <Popup key={"popup" + e} coordinates={e.position}>

                                            <div className="col" style={{padding: '.5rem'}}>

                                                { !cart[i].type && <div className="row g-4">
                                                    <div className="row g-4">
                                                        <h3>Selezionare tipologia</h3>
                                                    </div>
                                                    <div className="btn btn-primary col col-6 g-4" style={{marginBottom: "8px"}} onClick={() => {
                                                        cart[i].type = 'buy';
                                                        forceUpdate()
                                                        // @ts-ignore
                                                        window.update_cart_drawer && window.update_cart_drawer()
                                                    }}>Acquista</div>
                                                    <div className="btn btn-primary col col-6 g-4"  style={{marginBottom: "8px"}} onClick={() => {
                                                        cart[i].type = 'allow';
                                                        forceUpdate()
                                                        // @ts-ignore
                                                        window.update_cart_drawer && window.update_cart_drawer()
                                                    }}>Concedi</div>
                                                </div>}
                                                { cart[i].type &&
                                                    <div className="row g-4">
                                                        <h4 className="">Modalità: {cart[i].type === "buy" ? "Acquista" : (cart[i].type === "allow" ? "Concedi" : "Proposta")}</h4>
                                                    </div>}
                                                <div className="row g-4">
                                                    <h5>Guadagno previsto: {estimated} HNT/mese</h5>
                                                </div>
                                                <div className="row g-4">
                                                    <div className="btn btn-danger" onClick={() => {cart.splice(i, 1); forceUpdate()}}>
                                                        <KTSVG
                                                            path='/media/icons/duotune/general/gen027.svg'
                                                            className={"svg-icon-1 svg-icon-danger"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Popup>}
                                </>
                            )}

                            <Layer key={"plausible_area"} type="circle" paint={{
                                'circle-radius': pixelValue(lat, 550*2, zoom),
                                'circle-color': '#48d041',
                                'circle-opacity': 0.1
                            }}>
                                {[...Object.keys(popups)
                                    .filter((e: string) => fov.contains(popups[e].position))
                                    .filter((e: string) => popups[e].is_area_visible || showHelpPlacement)
                                    .map((e: string) =>
                                    <Feature coordinates={popups[e].position} onMouseLeave={() => {
                                        popups[e].is_area_visible = showHelpPlacement
                                        forceUpdate()
                                    }}/>
                                ), ...Object.keys(my_hotspots)
                                    .filter((e: string) => fov.contains(my_hotspots[e].position))
                                    .filter((e: string) => my_hotspots[e].is_area_visible || showHelpPlacement)
                                    .map((e: string) =>
                                    <Feature coordinates={my_hotspots[e].position} onMouseLeave={() => {
                                        my_hotspots[e].is_area_visible = showHelpPlacement
                                        forceUpdate()
                                    }}/>
                                ), ...cart.filter((e: any) => e.is_area_visible || showHelpPlacement).map((e: any, i:number) =>
                                    <Feature coordinates={e.position} onMouseLeave={() => {
                                        cart[i].is_area_visible = showHelpPlacement
                                        forceUpdate()
                                    }}/>
                                )]}
                            </Layer>

                            <Layer key={"nogo_area"} type="circle" paint={{
                                'circle-radius': pixelValue(lat, 250*2, zoom),
                                'circle-color': '#E54E52',
                                'circle-opacity': 0.2
                            }}>
                                {[...Object.keys(popups)
                                    .filter((e: string) => fov.contains(popups[e].position))
                                    .filter((e: string) => popups[e].is_area_visible || showHelpPlacement)
                                    .map((e: string) =>
                                    <Feature coordinates={popups[e].position}/>
                                ), ...Object.keys(my_hotspots)
                                    .filter((e: string) => fov.contains(my_hotspots[e].position))
                                    .filter((e: string) => my_hotspots[e].is_area_visible || showHelpPlacement)
                                    .map((e: string) =>
                                    <Feature coordinates={my_hotspots[e].position}/>
                                ), ...cart.filter((e: any) => e.is_area_visible || showHelpPlacement).map((e: any, i:number) =>
                                    <Feature coordinates={e.position}/>
                                )]}
                            </Layer>

                        </>
                    </Map>
                </div>
                <div className="row" style={{height: '3.5rem', marginTop: '.5rem'}}>
                    <div className="btn btn-primary" onClick={() => {
                        newHotspotMode = !newHotspotMode
                        forceUpdate();
                    }}>{newHotspotMode ? "Termina inserimento" : "Aggiungi hotspot"}</div>
                </div>
            </div>
        </div>

        {/*Modale di filter*/}
        <div
            className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px card card-custom'
            data-kt-menu='true'
            id='filter-menu'
        >
            <div className="card-header">
                <h3 className="card-title">Title</h3>
                <div className="card-toolbar">
                    <button type="button" className="btn btn-sm btn-light">
                        Opzioni di visualizzazione
                    </button>
                </div>
            </div>
            <div className="card-body">

                Opzioni aggiuntive:
                <div className="mb-10">
                    <div className="form-check form-switch form-check-custom form-check-solid">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            checked={showGlobalHS}
                            onClick={(e) => {
                                setShowGlobalHS(!showGlobalHS)
                            }}/>
                        <label className="form-check-label" htmlFor="flexSwitchChecked">
                            Hotspot nel mondo
                        </label>
                    </div>
                </div>

                <div className="mb-10">
                    <div className="form-check form-switch form-check-custom form-check-solid">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            checked={showMyHS}
                            disabled={!isAuthorized}
                            onClick={(e) => {
                                if(!isAuthorized)
                                    return;
                                setShowMyHS(!showMyHS)
                                setTimeout(() => {
                                    console.log(map)
                                    map.current.state.map.resize();
                                }, 100)
                            }}
                        />
                        <label className="form-check-label" htmlFor="flexSwitchChecked">
                            I miei Hotspot
                        </label>
                    </div>
                </div>


                <div className="separator my-10"></div>
                Consigli sul posizionamento:
                <div className="mb-10">
                    <div className="form-check form-switch form-check-custom form-check-solid">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            checked={showHelpPlacement}
                            onClick={(e) => {
                                setShowHelpPlacement(!showHelpPlacement)
                            }}/>
                        <label className="form-check-label" htmlFor="flexSwitchChecked">
                            {showHelpPlacement ? "Abilitati" : "Disabilitati"}
                        </label>
                    </div>
                </div>

            </div>
        </div>

    </>
}

const DashboardWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}
                       description={"Vista principale"}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
            <DashboardPage/>
        </>
    )
}

export {DashboardWrapper}
