import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'

export function PrivateRoutes() {
    // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
    // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
    const AccountPage = lazy(() => import('../pages/users/UserPageWrapper'))
    const StatsPageWrapper = lazy(() => import('../pages/stats/StatsPageWrapper'))
    const ListInstallations = lazy(() => import('../pages/list_installations/ListInstallations'))
    const ListUsers = lazy(() => import('../pages/list_users/ListUsers'))

    return (
        <Suspense fallback={<FallbackView/>}>
            <Switch>
                <Route path='/dashboard' component={DashboardWrapper}/>
                <Route path='/userprofile' component={AccountPage}/>
                <Route path='/statistics' component={StatsPageWrapper}/>

                <Route path='/installations' component={ListInstallations}/>
                <Route path='/users' component={ListUsers}/>
                {/*<Route path='/statistics' component={BuilderPageWrapper} />*/}
                {/*<Route path='/crafted/pages/profile' component={ProfilePage} />*/}
                {/*<Route path='/crafted/pages/wizards' component={WizardsPage} />*/}
                {/*<Route path='/crafted/widgets' component={WidgetsPage} />*/}
                {/*<Route path='/crafted/account' component={AccountPage} />*/}
                {/*<Route path='/apps/chat' component={ChatPage} />*/}
                {/*<Route path='/menu-test' component={MenuTestPage} />*/}
                <Route path='/' exact component={DashboardWrapper}/>

                <Redirect from='/auth' to='/dashboard'/>
                <Redirect exact from='/' to='/dashboard'/>
                <Redirect to='error/404'/>
            </Switch>
        </Suspense>
    )
}
